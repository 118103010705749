import { Injectable } from '@angular/core';
import {
  LaboratoryBatchAction,
  LaboratoryBatchActionTypes,
  LaboratoryKitInfo,
} from '@app/modules/laboratory/laboratory.types';
import { LaboratoryBatchService } from '@app/modules/laboratory/services/laboratory-batch.service';
import { GetLaboratoryBatchAction } from '@app/modules/laboratory/store/laboratory.actions';
import {
  LaboratoryBatchFilters,
  LaboratoryState,
} from '@app/modules/laboratory/store/laboratory.state';
import { GetSamplesListAction } from '@app/modules/service-data/store/sample.actions';
import { SampleState } from '@app/modules/service-data/store/sample.state';
import { db } from '@core/store/db';
import { LoadKitById } from '@core/store/kits/kits.actions';
import { KitsState } from '@core/store/kits/kits.state';
import { ProductsListState } from '@core/store/products/products.state';
import { Util } from '@core/utils/core.util';
import { HttpUtils } from '@core/utils/http-utils';
import { Store } from '@ngxs/store';
import { ConfirmService } from '@shared/components/confirm/confirm-dialog.component';
import { ConfirmCloseReason } from '@shared/shared.types';
import { combineLatest, forkJoin, from, Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryBatchUtilsService {
  private readonly POSITIONS_PER_PLATE = 60;
  constructor(
    private store: Store,
    private laboratoryBatchService: LaboratoryBatchService,
    private confirmService: ConfirmService
  ) {}

  public getPositionData(position: number) {
    // Determine which plate the position is on (1-based index)
    const plateNumber =
      Math.floor((position - 1) / this.POSITIONS_PER_PLATE) + 1;

    // Determine the position within the plate
    const positionWithinPlate = ((position - 1) % this.POSITIONS_PER_PLATE) + 1;

    return {
      position: position,
      plateNumber: plateNumber,
      positionOnPlate: positionWithinPlate,
    };
  }

  retrieveKitInfo(
    { kitId, add }: { kitId: string; add: boolean },
    batchId: string
  ): Observable<LaboratoryKitInfo> {
    return forkJoin([this.retrieveSample(kitId), this.retrieveKit(kitId)]).pipe(
      map(([sample, kit]) => ({ sample, kit })),
      switchMap((data) =>
        combineLatest([
          this.store
            .select(ProductsListState.getProduct)
            .pipe(map((getById) => getById(data?.sample?.productId))),
          db.getBatchSampleData(batchId, kitId),
        ]).pipe(
          map(([product, batchSample]) => ({
            ...data,
            product,
            kitId,
            batchSample,
          }))
        )
      ),
      first()
    );
  }

  retrieveSample(kitId: string) {
    return from(db.samples.where('kitId').equals(kitId).first()).pipe(
      switchMap((sample) => {
        if (sample) {
          return of(sample);
        } else {
          return this.store
            .dispatch(
              new GetSamplesListAction({ kitIds: [kitId] }, {}, null, true)
            )
            .pipe(
              switchMap(() =>
                this.store
                  .select(SampleState.getSampleByKitId)
                  .pipe(map((getByKitId) => getByKitId(kitId)))
              )
            );
        }
      }),
      first()
    );
  }

  retrieveKit(kitId: string) {
    return from(db.kits.get(kitId)).pipe(
      switchMap((kit) => {
        if (kit) {
          return of(kit);
        } else {
          return this.store
            .dispatch(new LoadKitById(kitId))
            .pipe(
              switchMap(() =>
                this.store
                  .select(KitsState.getKitById)
                  .pipe(map((getById) => getById(kitId)))
              )
            );
        }
      }),
      first()
    );
  }

  addBatchToDatabase(batchId: string) {
    return forkJoin([
      from(db.getLaboratoryBatchActions(batchId).pipe(first())),
      from(db.getLaboratoryBatch(batchId).pipe(first())),
    ]).pipe(
      switchMap(([actions, batch]) => {
        if (actions?.length && batch) {
          console.log(
            `${actions.length} actions in database, skipping batch ${batchId} reload.`
          );
          return of(null);
        } else {
          if (actions?.length) {
            console.warn(
              `${actions.length} stale actions found for batch ${batchId}. Removing from DB.`
            );
            db.clearBatchActions(batchId).subscribe();
          }
          console.log(`Storing ${batchId} to database.`);
          return this.store
            .dispatch(new GetLaboratoryBatchAction(batchId))
            .pipe(
              switchMap(() =>
                this.store.select(LaboratoryState.getLaboratoryBatchById)
              ),
              map((getById) => getById(batchId)),
              switchMap((batch) => db.addLaboratoryBatch(batch))
            );
        }
      })
    );
  }

  addBatchAction(
    batchId: string,
    kitId: string,
    type: LaboratoryBatchActionTypes,
    value?: string
  ) {
    return db.getLaboratoryBatch(batchId).pipe(
      first(),
      map((batch) => ({
        id: Util.CreateGuid(),
        kitId,
        type: type,
        value,
        timestamp: new Date(),
        batchStatus: batch.status,
        batchId,
        synced: false,
        deleted: false,
      })),
      switchMap((action) =>
        combineLatest([
          db.addLaboratoryBatchAction(action),
          this.handleBatchAction(action),
        ])
      )
    );
  }

  syncBatchActions(batchId: string) {
    return db.getLaboratoryBatchActions(batchId).pipe(
      switchMap((actions) =>
        this.laboratoryBatchService.syncActions(batchId, actions)
      ),
      switchMap((response) => {
        if (response) {
          return of(null);
        }
        return db.markBatchActionsAsSynced(batchId);
      })
    );
  }

  private handleBatchAction(action: LaboratoryBatchAction) {
    return db.getLaboratoryBatch(action.batchId).pipe(
      first(),
      switchMap((batch) => {
        switch (action.type) {
          case LaboratoryBatchActionTypes.ADDSAMPLE:
            if (
              !batch.samples.find((sample) => sample.kitId === action.kitId)
            ) {
              const sampleIndex = batch.dismissedSamples.findIndex(
                (s) => s.kitId === action.kitId
              );
              if (sampleIndex !== -1) {
                return this.confirmService
                  .confirm({
                    title: 'Add removed sample to active samples list',
                    message: `Are you sure you want to add removed sample <b>${action.kitId}</b> to the active samples list?`,
                  })
                  .pipe(
                    switchMap((result: { closeReason: ConfirmCloseReason }) => {
                      if (result.closeReason === ConfirmCloseReason.Yes) {
                        batch.samples.push(batch.dismissedSamples[sampleIndex]);
                        batch.dismissedSamples.splice(sampleIndex, 1);
                      }
                      return db.addLaboratoryBatch(batch);
                    })
                  );
              } else {
                batch.samples.push({
                  kitId: action.kitId,
                  id: Util.CreateGuid(),
                  position: (batch.samples.at(-1)?.position ?? 0) + 1,
                  tags: [],
                });
              }
            }
            break;
          case LaboratoryBatchActionTypes.REMOVESAMPLE: {
            const sampleIndex = batch.samples.findIndex(
              (s) => s.kitId === action.kitId
            );
            if (sampleIndex !== -1) {
              batch.dismissedSamples.push(batch.samples[sampleIndex]);
              batch.samples.splice(sampleIndex, 1);
            }
            break;
          }
          case LaboratoryBatchActionTypes.ADDTAG: {
            let sample = batch.samples.find((s) => s.kitId === action.kitId);
            if (!sample) {
              sample = batch.dismissedSamples.find(
                (s) => s.kitId === action.kitId
              );
            }
            if (!sample?.tags?.includes(action.value)) {
              sample.tags.push(action.value);
            }
            break;
          }
          case LaboratoryBatchActionTypes.REMOVETAG: {
            let sample = batch.samples.find((s) => s.kitId === action.kitId);
            if (!sample) {
              sample = batch.dismissedSamples.find(
                (s) => s.kitId === action.kitId
              );
            }
            sample.tags = sample.tags.filter((t) => t !== action.value);
            break;
          }
          default:
            break;
        }
        return db.addLaboratoryBatch(batch);
      })
    );
  }

  buildHttpParams(params: LaboratoryBatchFilters) {
    let httpParams = HttpUtils.buildHttpParams(params);

    if (params.ids?.length) {
      httpParams = httpParams.set('ids', params.ids.join(','));
    }

    if (params.laboratories?.length) {
      httpParams = httpParams.set(
        'laboratories',
        params.laboratories.join(',')
      );
    }

    return httpParams;
  }
}
