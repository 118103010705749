import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PAGING_HEADERS } from '@app/app.constants';
import {
  LaboratoryBatch,
  LaboratoryBatchAction,
} from '@app/modules/laboratory/laboratory.types';
import { ApiHeaders } from '@core/core.types';
import { HttpUtils } from '@core/utils/http-utils';
import { Store } from '@ngxs/store';
import { environment } from '@root/environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryBatchService {
  private readonly apiUrl = `${environment.apiUrl}/nipt-laboratory`;
  constructor(
    private store: Store,
    private http: HttpClient
  ) {}

  public createBatch(data: { laboratory: string }) {
    return this.http.post<{ id: string }>(`${this.apiUrl}/batch`, data);
  }

  public getBatchList(filters: HttpParams, headers: ApiHeaders) {
    console.log(headers);
    return this.http
      .get<LaboratoryBatch[]>(`${this.apiUrl}/batch`, {
        observe: 'response',
        params: filters,
        headers: HttpUtils.buildHttpHeaders(headers),
      })
      .pipe(
        map((response) => ({
          items: response.body,
          continuationToken: response.headers.get(
            PAGING_HEADERS.continuationToken
          ),
          totalCount: parseInt(
            response.headers.get(PAGING_HEADERS.totalCount),
            10
          ),
        }))
      );
  }

  public getBatch(batchId: string): Observable<LaboratoryBatch> {
    return this.http.get<LaboratoryBatch>(`${this.apiUrl}/batch/${batchId}`);
  }

  public syncActions(batchId: string, actions: LaboratoryBatchAction[]) {
    return this.http
      .post<void>(`${this.apiUrl}/batch/${batchId}/actions`, {
        actions,
      })
      .pipe(catchError(() => of({ error: true })));
  }

  public sort(batchId: string) {
    return this.http.patch<LaboratoryBatch>(
      `${this.apiUrl}/batch/${batchId}/sort`,
      {}
    );
  }

  public finish(batchId: string) {
    return this.http.patch<LaboratoryBatch>(
      `${this.apiUrl}/batch/${batchId}/finish`,
      {}
    );
  }

  public cancel(batchId: string) {
    return this.http.patch<LaboratoryBatch>(
      `${this.apiUrl}/batch/${batchId}/cancel`,
      {}
    );
  }
}
