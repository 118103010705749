import { Injectable } from '@angular/core';
import { LaboratoryBatch } from '@app/modules/laboratory/laboratory.types';
import { LaboratoryBatchService } from '@app/modules/laboratory/services/laboratory-batch.service';
import {
  CreateLaboratoryBatchAction,
  GetLaboratoryBatchAction,
  GetLaboratoryBatchListAction,
} from '@app/modules/laboratory/store/laboratory.actions';
import {
  ApiFilters,
  Pagination,
  SortDirection,
  StatePageSettings,
} from '@core/core.types';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { isEqual } from 'lodash';
import { EMPTY, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { LaboratoryBatchUtilsService } from '../services/laboratory-batch-utils.service';

const LABORATORY_STATE_NAME = 'laboratory';

export interface LaboratoryStateModel {
  batches: { [id: string]: LaboratoryBatch };
  createdBatchId: string;
  pages?: {
    [key: string]: {
      items: string[];
      continuationToken: string;
    };
  };
  currentPageSettings: StatePageSettings<LaboratoryBatchFilters>;
  totalCount?: number;
}

@State<LaboratoryStateModel>({
  name: LABORATORY_STATE_NAME,
  defaults: {
    batches: {},
    createdBatchId: null,
    pages: {},
    currentPageSettings: {
      pageIndex: 0,
      sortSettings: {
        orderBy: 'Generated',
        sortDirection: SortDirection.desc,
        pageSize: 30,
      },
    },
  },
})
@Injectable()
export class LaboratoryState {
  constructor(
    private laboratoryService: LaboratoryBatchService,
    private laboratoryBatchUtilsService: LaboratoryBatchUtilsService
  ) {}

  @Selector()
  static getLaboratoryBatchById(state: LaboratoryStateModel) {
    return (laboratoryBatchId: string) => state.batches[laboratoryBatchId];
  }

  @Selector()
  static getLaboratoryBatchesByIds(state: LaboratoryStateModel) {
    return (laboratoryBatchIds: string[]) =>
      laboratoryBatchIds.map(
        (laboratoryBatchId) => state.batches[laboratoryBatchId]
      );
  }

  @Selector()
  static getCreatedLaboratoryBatchId(state: LaboratoryStateModel) {
    return state.createdBatchId;
  }

  @Selector()
  static getCurrentPageIds(state: LaboratoryStateModel) {
    const {
      currentPageSettings: { pageIndex },
      pages,
    } = state;

    if (
      Object.keys(pages).length === 0 ||
      pageIndex > Object.keys(pages).length ||
      pageIndex < 0
    ) {
      return [];
    }
    return state.pages[pageIndex].items.slice();
  }

  @Selector()
  static getPagination({
    currentPageSettings,
    totalCount,
    pages,
  }: LaboratoryStateModel): Pagination<LaboratoryBatchFilters> {
    return {
      currentPageSettings: {
        ...currentPageSettings,
      },
      totalCount,
      pagesSize: Object.keys(pages).length,
      collectionSize: currentPageSettings.sortSettings.pageSize,
    };
  }

  @Action(GetLaboratoryBatchAction)
  getLaboratoryBatchAction(
    { patchState, getState }: StateContext<LaboratoryStateModel>,
    { id, justCreated }: GetLaboratoryBatchAction
  ) {
    return this.laboratoryService.getBatch(id).pipe(
      map((batch) =>
        patchState({
          batches: { ...getState().batches, [batch.id]: batch },
          createdBatchId: justCreated ? id : null,
        })
      )
    );
  }

  @Action(CreateLaboratoryBatchAction)
  createLaboratoryBatchAction(
    { patchState }: StateContext<LaboratoryStateModel>,
    { data }: CreateLaboratoryBatchAction
  ) {
    return this.laboratoryService
      .createBatch(data)
      .pipe(tap((resp) => patchState({ createdBatchId: resp.id })));
  }

  @Action(GetLaboratoryBatchListAction)
  getLaboratoryBatchListAction(
    { getState, patchState, dispatch }: StateContext<LaboratoryStateModel>,
    {
      filters,
      headers,
      pageToLoad = 1,
      reload = false,
    }: GetLaboratoryBatchListAction
  ) {
    // Appends objects are added, in case of separate state @Action calls, as they would otherwise override each other!
    const batchesAppends = {};

    const state = getState();
    let pages = { ...state.pages };
    const currentPageSettings = {
      ...state.currentPageSettings,
      sortSettings: { ...state.currentPageSettings.sortSettings },
      filterSettings: { ...state.currentPageSettings.filterSettings },
    };
    let totalCount = state.totalCount;

    let actualPageToLoad = pageToLoad ? pageToLoad - 1 : undefined;

    // Update pagination in case of listing pages.
    if (actualPageToLoad !== undefined) {
      currentPageSettings.pageIndex = actualPageToLoad;

      // Check that filters are same as they were for previous page.
      if (
        !isEqual(currentPageSettings.filterSettings, filters) ||
        currentPageSettings.pageSize !== headers.pagingTop ||
        currentPageSettings.sortSettings.sortDirection !==
          headers.orderDirection ||
        currentPageSettings.sortSettings.orderBy !== headers.orderBy
      ) {
        // If filters are different as they were -> update filters, reset pages object and go back to page 1.
        currentPageSettings.pageSize = headers.pagingTop;
        currentPageSettings.sortSettings.sortDirection = headers.orderDirection;
        currentPageSettings.sortSettings.orderBy = headers.orderBy;
        currentPageSettings.sortSettings.pageSize = headers.pagingTop;
        currentPageSettings.filterSettings = filters;

        actualPageToLoad = 0;
        reload = true;
        pages = {};
        currentPageSettings.pageIndex = actualPageToLoad;
      }
    }

    // Get selected page continuation token, if not first page
    if (actualPageToLoad > 0) {
      headers.continuationToken =
        pages[actualPageToLoad - 1]?.continuationToken;
    }

    // If not listing page, page doesnt exist or reload is demanded -> call API
    if (actualPageToLoad === undefined || !pages[actualPageToLoad] || reload) {
      return this.laboratoryService
        .getBatchList(
          this.laboratoryBatchUtilsService.buildHttpParams(filters),
          {
            ...headers,
            pagingTop: currentPageSettings.sortSettings.pageSize,
          }
        )
        .pipe(
          tap((response) => {
            response.items.forEach((batch) => {
              batchesAppends[batch.id] = batch;
            });

            // Add/update pages
            if (actualPageToLoad !== undefined) {
              totalCount = response.totalCount;
              pages[actualPageToLoad] = {
                items: response.items.map((serviceData) => serviceData.id),
                continuationToken: response.continuationToken,
              };
            }
          }),
          tap(() => {
            // get latest state again as state might be patched in some other @Action
            const oldState = getState();
            patchState({
              batches: { ...oldState.batches, ...batchesAppends },
              pages,
              totalCount,
              currentPageSettings,
            });
          }),
          catchError((err) => {
            patchState({});
            return of(null);
          })
        );
    } else {
      // If page exists -> update state so pagination is updated.
      patchState({ currentPageSettings });
      return EMPTY;
    }
  }
}

export interface LaboratoryBatchFilters extends ApiFilters {
  ids?: string[];
  laboratories?: string[];
  status?: string;
  generatedFrom?: string;
  generatedTo?: string;
}
