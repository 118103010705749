import Dexie, { Table } from 'dexie';
import { Kit } from '@core/core.types';
import { Sample } from '@app/modules/service-data/service-data.types';
import { BehaviorSubject, combineLatest, from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  LaboratoryBatch,
  LaboratoryBatchAction,
  LaboratoryBatchSample,
} from '@app/modules/laboratory/laboratory.types';

export class AppDB extends Dexie {
  samples!: Table<Sample, string>;
  kits!: Table<Kit, string>;
  laboratoryBatches!: Table<LaboratoryBatch, string>;
  laboratoryBatchActions!: Table<LaboratoryBatchAction, string>;

  stateUpdatedSubject = new BehaviorSubject<boolean>(true);

  constructor() {
    super('LaboratoryDatabase');
    this.version(1).stores({
      samples: 'id,kitId, dateOfVenipuncture',
      kits: 'id',
      laboratoryBatches: 'id,status',
      laboratoryBatchActions: 'id,kitId,batchId,timestamp',
    });
  }

  deleteOldData() {
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(fourteenDaysAgo.getDate() - 14);

    return combineLatest([
      from(
        this.laboratoryBatchActions
          .where('timestamp')
          .below(fourteenDaysAgo)
          .delete()
      ),
      from(
        this.samples
          .where('dateOfVenipuncture')
          .below(fourteenDaysAgo.toISOString())
          .toArray()
      ).pipe(
        switchMap((samples) =>
          combineLatest([
            from(
              this.kits
                .where('id')
                .anyOf(samples.map((sample) => sample.kitId))
                .delete()
            ),
            from(
              this.samples
                .where('id')
                .anyOf(samples.map((sample) => sample.id))
                .delete()
            ),
          ])
        )
      ),
    ]);
  }

  addSamplesList(samples: Sample[]) {
    return from(this.samples.bulkPut(samples));
  }

  addKitsList(kits: Kit[]) {
    return from(this.kits.bulkPut(kits));
  }

  addLaboratoryBatchAction(action: LaboratoryBatchAction) {
    return from(this.laboratoryBatchActions.put(action));
  }

  addLaboratoryBatch(batch: LaboratoryBatch) {
    return from(this.laboratoryBatches.put(batch)).pipe(
      tap(() => this.stateUpdatedSubject.next(true))
    );
  }

  clearBatchActions(batchId: string) {
    return from(
      this.laboratoryBatchActions
        .where('batchId')
        .equals(batchId)
        .and((action) => !action.synced)
        .and((action) => !action.deleted)
        .modify({ deleted: true })
    );
  }

  markBatchActionsAsSynced(batchId: string) {
    return from(
      this.laboratoryBatchActions
        .where('batchId')
        .equals(batchId)
        .and((action) => !action.synced)
        .and((action) => !action.deleted)
        .modify({ synced: true })
    );
  }

  getLaboratoryBatch(batchId: string) {
    return this.stateUpdatedSubject.pipe(
      switchMap(() => from(this.laboratoryBatches.get(batchId)))
    );
  }

  getBatchSampleData(
    batchId: string,
    kitId: string
  ): Observable<LaboratoryBatchSample> {
    return from(this.laboratoryBatches.get(batchId)).pipe(
      map((batch) => {
        let sample = batch.samples.find((sample) => sample.kitId === kitId);
        if (!sample) {
          sample = batch.dismissedSamples.find(
            (sample) => sample.kitId === kitId
          );
          if (sample) {
            sample.removed = true;
          }
        }
        return sample;
      })
    );
  }

  getLaboratoryBatchActions(batchId: string) {
    return this.stateUpdatedSubject.pipe(
      switchMap(() =>
        from(
          this.laboratoryBatchActions
            .where('batchId')
            .equals(batchId)
            .and((action) => !action.synced)
            .and((action) => !action.deleted)
            .toArray()
        )
      )
    );
  }
}

export const db = new AppDB();
