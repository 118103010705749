import {
  Sample,
  Timestamp,
} from '@app/modules/service-data/service-data.types';
import { Kit, Product } from '@core/core.types';

export interface LaboratoryBatch {
  id: string;
  laboratory: string;
  status: LaboratoryBatchStatus;
  samples: LaboratoryBatchSample[];
  dismissedSamples: LaboratoryBatchSample[];
  generated: Timestamp;
  sorted: Timestamp;
  finished: Timestamp;
}

export interface LaboratoryBatchAction {
  id: string;
  kitId: string;
  type: LaboratoryBatchActionTypes;
  timestamp: Date;
  batchStatus: LaboratoryBatchStatus;
  value?: string;
  batchId: string;
  synced: boolean;
  deleted: boolean;
}

export interface LaboratoryBatchSample {
  id: string;
  kitId: string;
  position: number;
  tags: string[];
  removed?: boolean;
}

export interface LaboratoryKitInfo {
  sample: Sample;
  kit: Kit;
  product: Product;
  kitId: string;
  batchSample?: LaboratoryBatchSample;
  add?: boolean;
}

export enum LaboratoryBatchStatus {
  GENERATED = 'Generated',
  SORTED = 'Sorted',
  FINISHED = 'Finished',
}

export enum LaboratoryBatchActionTypes {
  ADDSAMPLE = 'add_sample',
  REMOVESAMPLE = 'remove_sample',
  ADDTAG = 'add_tag',
  REMOVETAG = 'remove_tag',
}

export enum LaboratoryBatchItemConsentTag {
  ConsentMissing = 'ConsentMissing',
  DoctorSignatureMissing = 'DoctorSignatureMissing',
  PatientSignatureMissing = 'PatientSignatureMissing',
}

export const Priority = 'Priority';

export enum LaboratoryBatchItemDismissedTag {
  Over120h = 'Over120h',
  NotInApp = 'NotInApp',
  ExpiredTube = 'ExpiredTube',
  Hemolysis = 'Hemolysis',
  DamagedTube = 'DamagedTube',
  InsufficientVolume = 'InsufficientVolume',
  KitMixUp = 'KitMixUp',
  TestLimitation = 'TestLimitation',
  LabMistake = 'LabMistake',
  DHL = 'DHL',
  Other = 'Other',
}
