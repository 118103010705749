import { ApiHeaders } from '@core/core.types';
import { LaboratoryBatchFilters } from './laboratory.state';

export class CreateLaboratoryBatchAction {
  static type = '[Laboratory] CreateLaboratoryBatch';
  constructor(public data: { laboratory: string }) {}
}

export class GetLaboratoryBatchAction {
  static type = '[Laboratory] GetLaboratoryBatchAction';

  constructor(
    public id: string,
    public justCreated?: boolean
  ) {}
}

export class GetLaboratoryBatchListAction {
  static type = '[Laboratory] GetLaboratoryBatchListAction';
  constructor(
    public filters: LaboratoryBatchFilters,
    public headers: ApiHeaders,
    public pageToLoad: number,
    public reload: boolean
  ) {}
}
